import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography/Typography";
import { Button, IconButton, Link } from "@material-ui/core";
import { dateUtils } from "../../../../../utils";
import Tooltip from "@material-ui/core/Tooltip";
import { Theme } from "@material-ui/core/styles";
import { Pagination } from "../../../../../components/table";
import CreateIcon from "@material-ui/icons/Create";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import UpdateNotes from "../modals/UpdateNotes";
import TableWrap from "../../../../../components/TableWrap/TableWrap";

const useStyles = makeStyles((theme: Theme) => ({
    table: {
        minWidth: 767,
    },
    tableHead: {
        backgroundColor: "#EDF3F8",
    },
}));

export default function ProcessingNotes(props: {
    role: number | undefined, clientDetail: any, enrollmentClientNotes: any,
    page: any, rowsPerPage: number, setPage: Function, setRowsPerPage: Function, deleteEnrollmentNotes: Function, getClientNotes: Function
}) {
    const classes = useStyles();
    let {
        enrollmentClientNotes,
        page,
        rowsPerPage,
        setPage,
        setRowsPerPage,
        deleteEnrollmentNotes,
        getClientNotes,
    } = props;
    const [confirmationWindow, setConfirmationWindow] = React.useState<boolean>(false);
    const [notesId, setNotesId] = React.useState<number | null>(null);
    const [updateProcessingNotes, setUpdateProcessingNotes] = React.useState<boolean>(false);
    const [processingNotesMessage, setProcessingNotesMessage] = React.useState<string>("");

    const getSubject = (message: string) => {
        let msg: string = "";
        for (let index = 0; index < 30; index++) {
            msg += message[index];
        }
        return msg + "...";
    };

    const getRole = (role: number) => {
        if (role === 1 || role === 2) {
            return "Admin";
        }
        if (role === 3) {
            return "Imo";
        }
        if (role === 4) {
            return "Agent";
        }
        if (role === 12) {
            return "Trustee";
        }
        return "Client";
    };

    const deleteProcessingNotes = () => {
        deleteEnrollmentNotes(notesId);
    };

    const handleClose = () => {
        setUpdateProcessingNotes(false);
    };

    return (
            <React.Fragment>
                <TableWrap>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell>Message Subject</TableCell>
                            <TableCell align="left">Message Author</TableCell>
                            <TableCell align="left">Date</TableCell>
                            <TableCell align="left">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!enrollmentClientNotes.rows || enrollmentClientNotes.rows.length === 0 ?
                                <TableRow key={1}>
                                    <TableCell component="th" scope="row">
                                        No notes yet.
                                    </TableCell>
                                </TableRow>
                                :
                                (enrollmentClientNotes.rows && enrollmentClientNotes.rows.map((note: any, index: number) => {
                                            return (
                                                    <TableRow key={index}>
                                                        <TableCell component="th" scope="row">
                                                            {note.message != "" ?
                                                                    <Tooltip
                                                                            title={
                                                                                <React.Fragment>
                                                                                    <Typography variant="caption"
                                                                                                color="inherit"
                                                                                                className="mb0">{note.message}</Typography>
                                                                                </React.Fragment>
                                                                            }
                                                                    >
                                                                        <Link
                                                                                style={{ "cursor": "pointer" }}
                                                                        >
                                                                            {note && note.message && note.message.length > 30 ?
                                                                                    getSubject(note.message)
                                                                                    : note.message
                                                                            }
                                                                        </Link>
                                                                    </Tooltip>
                                                                    :
                                                                    ""
                                                            }
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {note.author &&
                                                                    <span>
                                                                        {note.author.firstName + " " + note.author.lastName + " (" + getRole(note.author.role) + ")"}
                                                                        </span>
                                                            }
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {dateUtils.asDbDateFormat(note.createdAt)}
                                                        </TableCell>
                                                        <TableCell padding="default" align="center" className="w230">
                                                            <Tooltip title="View">
                                                                <IconButton
                                                                        color="default"
                                                                        onClick={() => {
                                                                            setUpdateProcessingNotes(true);
                                                                            setProcessingNotesMessage(note && note.message);
                                                                            setNotesId(note && note.id);
                                                                        }}
                                                                >
                                                                    <VisibilityIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Edit">
                                                                <IconButton
                                                                        color="default"
                                                                        onClick={() => {
                                                                            setUpdateProcessingNotes(true);
                                                                            setProcessingNotesMessage(note && note.message);
                                                                            setNotesId(note && note.id);
                                                                        }}
                                                                >
                                                                    <CreateIcon/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete">
                                                                <IconButton
                                                                        color="default"
                                                                        onClick={
                                                                            () => {
                                                                                setConfirmationWindow(true);
                                                                                setNotesId(note && note.id);
                                                                            }}
                                                                >
                                                                    <DeleteOutlineIcon className="colorRed"/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                            );
                                        },
                                ))
                        }
                    </TableBody>
                </TableWrap>
                <Pagination page={Number(page)} rowsPerPage={Number(rowsPerPage)}
                            setPage={setPage}
                            setRowsPerPage={setRowsPerPage}
                            accountList={enrollmentClientNotes}/>

                {confirmationWindow &&
                        <Dialog
                                fullWidth={true}
                                maxWidth="sm" aria-labelledby="customized-dialog-title"
                                open={confirmationWindow}>
                            <DialogContent dividers>
                                <Typography variant="body1" className="mb0"> Are you sure to delete?</Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => deleteProcessingNotes()}
                                >
                                    YES
                                </Button>
                                <Button
                                        className="bgRed"
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setConfirmationWindow(false)}
                                >
                                    NO
                                </Button>
                            </DialogActions>
                        </Dialog>
                }
                <UpdateNotes
                        updateNotes={updateProcessingNotes}
                        handleClose={handleClose}
                        notesMessage={processingNotesMessage}
                        notesId={notesId}
                        getClientNotes={getClientNotes}
                        updateFor={'processing'}
                />
            </React.Fragment>
    );
}
