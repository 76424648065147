import React from "react";
import {
    TextField,
    Button,
    Typography,
    Grid,
} from "@material-ui/core";
import * as Yup from "yup";
import { Label } from "../../../formInputs";
import { Formik, useFormikContext } from "formik";
import { REQUIRED_FIELD } from "../../../../constants";
import { ErrorCommon, TextFieldCommon } from "../../../formikFormInputs";
import { Dialog as GenericDialog, GenericDialogProps } from "../../../dialog";
import { useActions } from "../../../../actions";
import * as ClientActions from "../../../../actions/client";

const validationSchema = Yup.object({
    rejectionMessage: Yup.string().required(`Rejection message is ${REQUIRED_FIELD}`),
    internalNote: Yup.string().required(`Internal note is ${REQUIRED_FIELD}`),
});

export default function RejectChangingRequest({ open, setChangingRequest, requestType, clientId, role, refreshPage }: {
    open: boolean,
    setChangingRequest: Function,
    requestType: string,
    clientId: number,
    role: number,
    refreshPage: Function
}) {
    const clientActions = useActions(ClientActions);
    const initialValues = {
        rejectionMessage: "",
        internalNote: "",
    };
    const handleSubmit = async (values, { setSubmitting }) => {
        await clientActions.createClientActionItems({
            actionType: requestType,
            clientId: clientId,
            role: role,
            rejectionMessage: values["rejectionMessage"],
            internalNote: values["internalNote"],
        });
        setSubmitting(false);
        setChangingRequest(false);
        refreshPage();
    };

    const dialogProps: GenericDialogProps = {
        "aria-labelledby": "dialog-title",
        fullWidth: true,
        maxWidth: "sm",
        content: (
                <>
                    <Typography variant="body1" className="mb15 floatLeft w100"> The client will receive an email and
                        action item to correct the requested changes.</Typography>
                    <Formik
                            initialValues={initialValues}
                            enableReinitialize={true}
                            validationSchema={validationSchema}
                            validateOnChange={true}
                            onSubmit={handleSubmit}
                    >
                        {(formikProps) => (
                                <FormContent {...formikProps} />
                        )}
                    </Formik>
                </>
        ),
        dividers: true,
        onClose: () => setChangingRequest(false),
        open: open,
        title: "Request Changes",
    };
    return (
            <GenericDialog {...dialogProps} />
    );

    function FormContent(props: any) {
        const {
            values,
            errors,
            touched,
            isSubmitting,
            dirty,
            handleChange,
            handleBlur,
        } = useFormikContext<any>();
        const fieldObj: any = [
            {
                id: "rejectionMessage",
                name: "rejectionMessage",
                label: <Label label={"Rejection Message to Client"} required={true} />,
                isRequired: true,
                component: TextField,
                variant: "outlined",
                multiline: true,
                rows: 4,
            },
            {
                id: "internalNote",
                name: "internalNote",
                label: <Label label={"Internal NIW Note"} required={true} />,
                isRequired: true,
                component: TextField,
                variant: "outlined",
                multiline: true,
                rows: 2,
            },
        ];
        return (
                <form onSubmit={props.handleSubmit}>
                    <Grid container spacing={2}>
                        {fieldObj.map((field, index) => {
                            return (
                                    <Grid item xs={12} key={index}>
                                        <TextFieldCommon
                                                {...field}
                                                values={values}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                        />
                                        <ErrorCommon errors={errors}
                                                     name={field.name}
                                                     touched={touched} />
                                    </Grid>
                            );
                        })
                        }
                        <Grid item xs={12}>
                            <Button
                                    size="small"
                                    type="submit"
                                    color="primary"
                                    className="floatRight mr10"
                                    variant="contained"
                                    disabled={(Object.keys(errors).length !== 0) || !dirty || isSubmitting}
                            >
                                Submit
                            </Button>
                            <Button onClick={() => setChangingRequest(false)}
                                    size="small"
                                    color="default"
                                    className="floatRight mr10"
                                    variant="contained"
                                    disabled={isSubmitting}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </form>
        );
    }
}